//
// Misc: Miscellaneous
//

// Image sizes
.img-size-64,
.img-size-50,
.img-size-32 {
  height: auto;
}

.img-size-64 {
  width: 64px;
}

.img-size-50 {
  width: 50px;
}

.img-size-32 {
  width: 32px;
}
