//
// Component: Table
//

.table {
  &:not(.table-dark) {
    color: inherit;
  }

  // fixed table head
  &.table-head-fixed {
    thead tr:nth-child(1) th {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: $white;
      border-bottom: 0;
      box-shadow: inset 0 1px 0 $table-border-color, inset 0 -1px 0 $table-border-color;
    }

    // TODO: add $table-dark
    // &.table-dark {
    //   thead tr {
    //     &:nth-child(1) th {
    //       background-color: $table-dark-bg;
    //       box-shadow: inset 0 1px 0 $table-dark-border-color, inset 0 -1px 0 $table-dark-border-color;
    //     }
    //   }
    // }
  }

  // no border
  &.no-border {
    &,
    td,
    th {
      border: 0;
    }
  }

  // .text-center in tables
  &.text-center {
    &,
    td,
    th {
      text-align: center;
    }
  }

  &.table-valign-middle {
    thead > tr > th,
    thead > tr > td,
    tbody > tr > th,
    tbody > tr > td {
      vertical-align: middle;
    }
  }

  .card-body.p-0 & {
    thead > tr > th,
    thead > tr > td,
    tfoot > tr > th,
    tfoot > tr > td,
    tbody > tr > th,
    tbody > tr > td {
      &:first-of-type {
        padding-left: map-get($spacers, 4);
      }

      &:last-of-type {
        padding-right: map-get($spacers, 4);
      }
    }
  }
}
