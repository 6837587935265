//
// Mixins: Scrollbar
//

@mixin scrollbar-color-gray() {
  scrollbar-color: var(--#{$prefix}secondary-bg) transparent;

  &::-webkit-scrollbar-thumb {
    background-color: var(--#{$prefix}secondary-bg);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin scrollbar-width-thin() {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }
}

@mixin scrollbar-width-none() {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}
