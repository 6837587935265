//
// Core: Layout
//

.app-wrapper {
  position: relative;
  display: grid;
  grid-template-areas:
    "#{$lte-prefix}app-sidebar #{$lte-prefix}app-header"
    "#{$lte-prefix}app-sidebar #{$lte-prefix}app-main"
    "#{$lte-prefix}app-sidebar #{$lte-prefix}app-footer";
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  align-content: stretch;
  align-items: stretch;
  max-width: 100vw;
  min-height: 100vh;

  > * {
    min-width: 0;
  }
}
