//
// Core: Main Header
//

.app-header {
  z-index: $lte-zindex-app-header;
  grid-area: #{$lte-prefix}app-header;
  max-width: 100vw;
  border-bottom: $lte-app-header-bottom-border;
  @include transition($lte-transition-speed $lte-transition-fn);

  .nav-link {
    position: relative;
    height: $nav-link-height;
  }
}

// Navbar badge
.navbar-badge {
  position: absolute;
  top: 9px;
  right: 5px;
  padding: 2px 4px;
  font-size: .6rem;
  font-weight: 400;
}
