//
// Core: Main Footer
//

.app-footer {
  grid-area: #{$lte-prefix}app-footer;
  width: inherit;
  max-width: 100vw;
  min-height: 3rem;
  padding: $lte-app-footer-padding;
  color: $lte-app-footer-color;
  background-color: $lte-app-footer-bg;
  border-top: $lte-app-footer-border-top;
  @include transition($lte-transition-speed $lte-transition-fn);
}
